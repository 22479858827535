import Axios from 'axios';
import store from '@/store'
import http from "..";
import router from '@/router'

class TipoEmpresaService {
    Consultar(
        datos = {
            Id: -1,
            Descripcion: '',
            indActivo:null
        }
    ) {
        return http.get("TipoEmpresa/consultar", { params: datos })
        .catch((error) => {
            if (error.response.status == 401) {
                store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
                router.push("/pages/login");
            }
        });
    }
}

export default new TipoEmpresaService();