<template>
    <div>
        <v-card>
            <v-card-subtitle>Lista Empresa
                <v-btn icon @click="cargar()" color="green" :loading="cargandoDatos" :disabled="cargandoDatos">
                    <v-icon>{{ icons.mdiCached }}</v-icon>
                </v-btn>
            </v-card-subtitle>
            <v-card-text>
                <v-row>
                    <v-col cols="12" offset-md="8" md="4">
                        <v-text-field v-model="search" :append-icon="icons.mdiMagnify" label="Buscar" single-line
                            hide-details dense outlined></v-text-field>
                    </v-col>
                </v-row>
            </v-card-text>
            <!-- Table -->
            <v-data-table :headers="headers" :items="Lista" :search="search" :items-per-page="5" :loading="cargandoDatos"
                class="table-kitchen-sink">
                <template #[`item.nombre`]="{ item }">
                    <div class="d-flex align-center">
                       {{ item.nombreResponsable }} {{ item.apellidoResponsable }}
                    </div>
                </template>
                <template #[`item.indActivo`]="{ item }">
                    <div class="d-flex align-center">
                        <v-icon  color="success" v-if="item.indActivo == true" >{{icons.mdiCheck}}</v-icon>
                        <v-icon  color="success" v-else >{{icons.mdiCancel}}</v-icon>
                    </div>
                </template>
                <template #[`item.accion`]="{ item }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <a class="pr-2" v-bind="attrs" v-on="on" @click="GetEditar(item)">
                                <v-icon >{{ icons.mdiPencil }}</v-icon>
                            </a>
                        </template>
                        <span>Editar</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn class="pr-2" icon v-bind="attrs" v-on="on" :loading="item.cargando" @click="CambiarIndActivo(item)">
                                <v-icon>{{ item.indActivo  ? icons.mdiCancel : icons.mdiCheck }} </v-icon>
                            </v-btn>
                        </template>
                        <span>{{ item.indActivo  ? 'Inactivar' : 'Activar' }}</span>
                    </v-tooltip>
                </template>
            </v-data-table>
            <!-- End Table -->
        </v-card>
    </div>
</template>
<script>
import { onBeforeMount, ref, context } from '@vue/composition-api'
import {
    mdiMagnify,
    mdiDeleteOutline,
    mdiStoreEdit,
    mdiPencilOutline,
    mdiAccountCancel,
    mdiAccountEdit,
    mdiAccountCheck,
    mdiCheck,
    mdiCancel,
    mdiStoreEditOutline,
    mdiPencil,
    mdiCached,
} from '@mdi/js'
import config from '@/api/config'  
import store from '@/store'
import EmpresaServices from "../../../../../api/servicios/EmpresaServices"
export default {
    setup(props, context) {
        const search = ref('')
        const Lista = ref([])
        const ListaLoad = ref(false)
        const cargandoDatos = ref(false)
        const GetEditar = item => {
            console.log(item)
            context.emit('GetEditar', { ...item })
            
        }
        onBeforeMount(() => {
            cargar()
        });

        const cargar = () => {
            cargandoDatos.value = true
            try {

                EmpresaServices.EmpresaConsultar({
                    Id: -1,
                    Descripcion: '',
                }).then(response => {
                    console.log(response)
                    if (response.data.estatus == true) {
                        Lista.value = response.data.datos
                    }
                })
                .catch(error => {
                    console.log(error)
                })
                .finally(() => {
                    cargandoDatos.value = false
                }) 
            } catch (error) {
                //alert(error)
                cargandoDatos.value = false
            }
        }

        const CambiarIndActivo = (item) => {
            try {
                item.cargando = true
                EmpresaServices.Actualizar(
                    store.state.user.idUsuario,
                    item.id,
                    item.idTipoEmpresa,
                    item.idMoneda,
                    item.documento,
                    item.razonSocial,
                    item.razonComercial,
                    item.registro,
                    item.documentoResponsable,
                    item.nombreResponsable,
                    item.apellidoResponsable,
                    item.telefonoResponsable,
                    item.correoResponsable,
                    item.imagen1,
                    item.imagen2,
                    item.direccion1,
                    item.direccion2,
                    item.direccion3,
                    !item.indActivo,
                ).then(response => {
                    console.log(response)
                    if (response.data.estatus == true) {
                        store.commit('setAlert', {
                            message: response.data.mensaje,
                            type: 'success',
                        });
                        item.cargando = false
                        item.indActivo = !item.indActivo;
                    } else {

                        store.commit('setAlert', {
                            message: `${response.data.mensaje}: ` + response.data.datos.mensajeError,
                            type: 'warning',
                        });
                        item.cargando = false
                    }
                })
                    .catch(error => {
                        console.log(error)
                        store.commit('setAlert', {
                            message: error,
                            type: 'error',
                        });
                        item.cargando = false
                    })
                    .finally(() => {
                        item.cargando = false
                    })
            } catch (error) {
                //alert(error)
                store.commit('setAlert', {
                    message: error,
                    type: 'error',
                })
                item.cargando = false
            }
        }
        return{
            icons: {
                mdiMagnify,
                mdiDeleteOutline,
                mdiPencilOutline,
                mdiAccountCancel,
                mdiAccountEdit,
                mdiAccountCheck,
                mdiCheck,
                mdiCancel,
                mdiStoreEdit,
                mdiStoreEditOutline,
                mdiPencil,
                mdiCached
            },
            search,
            headers: [
                { text: 'Id', value: 'id' },
                { text: 'Razón social', value: 'razonSocial' }, 
                { text: 'Razón comercial', value: 'razonComercial ' },
                { text: 'Documento', value: 'documento' },
                { text: 'Documento responsable', value: 'documentoResponsable' },
                { text: 'Nombre responsable', value: 'nombre' },
                { text: 'Correo responsable', value: 'correoResponsable' },
                { text: 'Activo', value: 'indActivo' },
                { text: 'Acción', value: 'accion', sortable: false },
            ],
            Lista,
            ListaLoad,
            GetEditar,
            cargar,
            config,
            cargandoDatos,
            CambiarIndActivo,
        }
    }
}
</script>