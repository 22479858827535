<template>
  <v-card flat class="pa-3 mt-2">
    <v-card-subtitle> <strong>Empresa</strong> </v-card-subtitle>
    <v-form ref="form" lazy-validation>
      <v-card-text class="d-flex">
        <v-row>
          <v-col cols="12">
            <v-row>
              <v-col cols="12" lg="12">
                <v-row>
                  <v-col cols="12" lg="9">
                    <v-row>
                      <v-col cols="12" lg="4">
                        <v-text-field
                          :rules="reglas.documento"
                          v-model="datos.documento"
                          label="Documento de la empresa *"
                          required
                          outlined
                          dense
                          hide-details="auto"
                          class=""
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" lg="4">
                        <v-text-field
                          :rules="reglas.razonSocial"
                          v-model="datos.razonSocial"
                          label="Razón social *"
                          required
                          outlined
                          dense
                          hide-details="auto"
                          class=""
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" lg="4">
                        <v-text-field
                          :rules="reglas.razonComercial"
                          v-model="datos.razonComercial"
                          label="Razón comercial *"
                          required
                          outlined
                          dense
                          hide-details="auto"
                          class=""
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" lg="4">
                        <v-text-field
                          :rules="reglas.registro"
                          v-model="datos.registro"
                          label="Registro mercantil *"
                          required
                          outlined
                          dense
                          hide-details="auto"
                          class=""
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" lg="4">
                        <SelectMoneda
                          ref="SelectMonedaRef"
                          :clearable="true"
                          :dense="true"
                          :outlined="true"
                          :icon="false"
                          :rules="reglas.moneda"
                          v-model="datos.idMoneda"
                        >
                        </SelectMoneda>
                      </v-col>
                      <v-col cols="12" lg="4">
                        <SelectTipoEmpresa
                          ref="SelectTipoEmpresaRef"
                          :clearable="true"
                          :dense="true"
                          :outlined="true"
                          :icon="false"
                          :rules="reglas.TipoEmpresa"
                          v-model="datos.idTipoEmpresa"
                        >
                        </SelectTipoEmpresa>
                      </v-col>

                    
                    </v-row>

                    <v-divider class="mt-4   "></v-divider>
                    <div  class="mt-4  mb-4">

                        <strong>Direccion</strong>
                    </div>
                    <v-row>
                      <v-col cols="12" lg="4">
                        <v-text-field
                          :rules="reglas.direccion1"
                          v-model="datos.direccion1"
                          label="Calle/Av *"
                          required
                          outlined
                          dense
                          hide-details="auto"
                          class=""
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" lg="4">
                        <v-text-field
                          :rules="reglas.direccion2"
                          v-model="datos.direccion2"
                          label="Local *"
                          required
                          outlined
                          dense
                          hide-details="auto"
                          class=""
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" lg="4">
                        <v-text-field
                          :rules="reglas.direccion3"
                          v-model="datos.direccion3"
                          label="Ciudad/Estado *"
                          required
                          outlined
                          dense
                          hide-details="auto"
                          class=""
                        >
                        </v-text-field>
                      </v-col>

                    </v-row>
                
                    <v-divider class="mt-4   "></v-divider>
                    <div  class="mt-4  mb-4">

                        <strong>Responsable</strong>
                    </div>
                    <v-row>
                      <v-col cols="12" lg="4">
                        <v-text-field
                          :rules="reglas.documentoResponsable"
                          v-model="datos.documentoResponsable"
                          label="Documento responsable *"
                          required
                          outlined
                          dense
                          hide-details="auto"
                          class=""
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" lg="4">
                        <v-text-field
                          :rules="reglas.nombreResponsable"
                          v-model="datos.nombreResponsable"
                          label="Nombre responsable *"
                          required
                          outlined
                          dense
                          hide-details="auto"
                          class=""
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" lg="4">
                        <v-text-field
                          :rules="reglas.apellidoResponsable"
                          v-model="datos.apellidoResponsable"
                          label="Apellido responsable *"
                          required
                          outlined
                          dense
                          hide-details="auto"
                          class=""
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" lg="4">
                        <v-text-field-simplemask
                          class=""
                          v-model="datos.telefonoResponsable"
                          v-bind:label="'Teléfono'"
                          v-bind:properties="{
                            outlined: true,
                            clearable: false,
                            dense: true,
                            rules: reglas.telefonoResponsable,
                          }"
                          v-bind:options="{
                            inputMask: '(####) ### ## ##',
                            outputMask: '###########',
                            empty: null,
                            applyAfter: false,
                            alphanumeric: false,
                            lowerCase: true,
                          }"
                        />
                      </v-col>
                      <v-col cols="12" lg="4">
                        <v-text-field
                          :rules="reglas.correoResponsable"
                          v-model="datos.correoResponsable"
                          label="Correo responsable *"
                          required
                          outlined
                          dense
                          hide-details="auto"
                          class=""
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col cols="12" lg="3">
                    <h4>Imagen corporativa</h4>
                    <CropImagenBase64
                      ref="CropImagenBaseRef"
                      :urlImgDefault="require('@/assets/images/logos/logo.png')"
                      :urlImgValue="datos.imagen2"
                    ></CropImagenBase64>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="text-right">
            <v-btn color="secondary" class="me-3" :disabled="guardando" @click="limpiar()" small> Limpiar </v-btn>
            <v-btn color="primary" class="me-3" :loading="guardando" small @click="Guardar()"> Guardar </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-form>
  </v-card>
</template>
<script>
import { onBeforeMount, ref } from '@vue/composition-api'
import store from '@/store'
import goTo from 'vuetify/lib/services/goto'
import SelectMoneda from '../../../producto/maestro/moneda/componentes/Select.vue'
import SelectTipoEmpresa from './SelectTipoEmpresa.vue'
import CropImagenBase64 from '@/components/CropImagenBase64.vue'
import EmpresaServices from '../../../../../api/servicios/EmpresaServices'
export default {
  components: {
    SelectMoneda,
    CropImagenBase64,
    SelectTipoEmpresa,
  },
  setup(props, context) {
    const guardando = ref(false)
    const form = ref(null)
    const SelectMonedaRef = ref(null)
    const SelectTipoEmpresaRef = ref(null)
    const CropImagenBaseRef = ref(null)
    const reglas = {
      documento: [
        v => !!v || 'Documento es requerido',
        v => /^([J,G,V]{1}[\-]\d{5,9}[\-]\d{1})$/.test(v) || 'Documento no valido ejemplo: J-0000000-0',
      ],
      razonSocial: [v => !!v || 'Razón social es requerido'],
      razonComercial: [v => !!v || 'Razón comerial es requerido'],
      registro: [v => !!v || 'Registro mercantil es requerido'],
      moneda: [v => !!v || 'Moneda es requerido'],

      TipoEmpresa: [v => !!v || 'Tipo Empresa es requerido'],

      documentoResponsable: [
        v => !!v || 'Documento del responsable es requerido',
        v => /^(\d{6,9})$/.test(v) || 'Documento no valido',
      ],
      nombreResponsable: [v => !!v || 'Nombre del responsable del responsable es requerido'],

      apellidoResponsable: [v => !!v || 'Apellido del responsable es requerido'],
      telefonoResponsable: [
        v => !!v || 'Teléfono del responsable es requerido',
        v =>
          /(\(0414|0412|0416|0426|0424)?[ -]*([0-9][ -]*){3}([0-9][ -]*){2}[ -]*([0-9][ -]*){2}$/.test(v) ||
          'Teléfono no valido',
      ],
      correoResponsable: [
        v => !!v || 'Correo del responsable es requerido',
        v =>
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v,
          ) || 'Correo no valido',
      ],
      direccion1: [v => !!v || 'Calle/Avenida es requerido'],
      direccion1: [v => !!v || 'Local es requerido'],
      direccion2: [v => !!v || 'Ciudad/Estado es requerido'],
    }
    const datosInit = {
      id: -1,
      idTipoEmpresa: -1,
      idMoneda: -1,
      documento: null,
      razonSocial: null,
      razonComercial: null,
      registro: null,
      documentoResponsable: null,
      nombreResponsable: null,
      apellidoResponsable: null,
      telefonoResponsable: null,
      correoResponsable: null,
      imagen1: null,
      imagen2: null,
      direccion1: null,
      direccion2: null,
      direccion3: null,
      indActivo: true,
    }

    const datos = ref(JSON.parse(JSON.stringify(datosInit)))
    const limpiar = () => {
      datos.value = JSON.parse(JSON.stringify(datosInit))
      form.value.resetValidation()
    }
    const cargandos = ref(false)
    const CargarEditar = item => {
      datos.value = item
      SelectMonedaRef.value.selectId(item.idMoneda)
      SelectTipoEmpresaRef.value.selectId(item.idTipoEmpresa)
      CropImagenBaseRef.value.resetImg()
      goTo(0)
    }

    const validarForm = () => {
      let val = form.value?.validate()

      return val
    }
    const ActualizarLista = item => {
      console.log(item)
      context.emit('GetActualizarLista', { ...item })
    }

    const Guardar = () => {
      guardando.value = true
      console.log('IMAGEN', CropImagenBaseRef.value.urlImg)
      if (validarForm()) {
        try {
          EmpresaServices.Actualizar(
            store.state.user.idUsuario,
            datos.value.id,
            datos.value.idTipoEmpresa,
            datos.value.idMoneda,
            datos.value.documento,
            datos.value.razonSocial,
            datos.value.razonComercial,
            datos.value.registro,
            datos.value.documentoResponsable,
            datos.value.nombreResponsable,
            datos.value.apellidoResponsable,
            datos.value.telefonoResponsable,
            datos.value.correoResponsable,
            datos.value.imagen1,
            (datos.value.imagen2 = CropImagenBaseRef.value.urlImg),
            datos.value.direccion1,
            datos.value.direccion2,
            datos.value.direccion3,
            datos.value.indActivo,
          )
            .then(response => {
              console.log(response)
              if (response.data.estatus == true) {
                store.commit('setAlert', {
                  message: response.data.mensaje,
                  type: 'success',
                })
                ActualizarLista(response.data.datos.datos)
                limpiar()
              } else {
                store.commit('setAlert', {
                  message: `${response.data.mensaje}: ` + response.data.datos.mensajeError,
                  type: 'warning',
                })
              }
            })
            .catch(error => {
              console.log(error)
              store.commit('setAlert', {
                message: error,
                type: 'error',
              })
            })
            .finally(() => {
              guardando.value = false
            })
        } catch (error) {
          //alert(error)
          store.commit('setAlert', {
            message: error,
            type: 'error',
          })
          guardando.value = false
        }
      } else {
        store.commit('setAlert', {
          message: 'Verifique que todos los datos estén completos',
          type: 'warning',
        })
      }
    }

    return {
      guardando,
      form,
      reglas,
      SelectMonedaRef,
      datos,
      cargandos,
      CropImagenBaseRef,
      SelectTipoEmpresaRef,
      limpiar,
      CargarEditar,
      validarForm,
      ActualizarLista,
      Guardar,
    }
  },
}
</script>